import Image from 'next/legacy/image';
import type { FunctionComponent } from 'react';
import { useRef } from 'react';
import { Markdown } from '../../../../components/markdown';
import type { BentoPieceSquareFragment } from '../../../../graphql/queries/GetBentoPieceSquare.graphql';
import { useDivDimensions } from '../../../../lib/hooks/useDivDimensions';
import { usePersonalizations } from '../../../../lib/hooks/usePersonalizations';
import { renderTemplate } from '../../../../lib/utils/text';
import variables from '../../../../styles/variables.module.scss';
import { ActionButton } from '../../../action-button';
import styles from './bento-square.module.scss';
export const BentoSquare: FunctionComponent<Props> = ({
  layout = 'text',
  justifyContent = 'flex-start',
  headingMd = '',
  headingColor = 'white',
  subtitleMd = '',
  button,
  copyImage,
  copyImageLocation = 'top',
  backgroundType = 'color',
  backgroundColor = 'darkGray',
  backgroundImage = null,
  backgroundVideo = null,
  image = null,
  alignImage = 'center',
  isMarketplace = false
}) => {
  const personalizations = usePersonalizations();
  const containerRef = useRef(null);
  const {
    divWidth,
    divHeight
  } = useDivDimensions(containerRef);
  function getBackgroundStyle() {
    if (backgroundType === 'video') {
      return {
        minHeight: '300px'
      };
    }
    if (backgroundType === 'image') {
      return {
        backgroundImage: getBackgroundImageUrl()
      };
    }
    return {
      backgroundColor: variables[backgroundColor]
    };
  }
  function getBackgroundImageUrl() {
    return `url(${backgroundImage?.url})`;
  }
  function getHeadingStyle() {
    if (headingColor === 'gradient') return;
    return {
      color: headingColor
    };
  }
  function getBgDimensions() {
    return {
      width: divWidth,
      height: divHeight
    };
  }
  return <div className={`${styles.boxOne} ${isMarketplace ? styles.marketplace : ''}`} style={getBackgroundStyle()} data-sentry-component="BentoSquare" data-sentry-source-file="bento-square.tsx">
            <div className={styles.boxOneContent} ref={containerRef}>
                {backgroundVideo && backgroundType === 'video' && <div className={styles.videoContainer} style={getBgDimensions()}>
                        <video className={styles.backgroundVideo} autoPlay muted playsInline webkit-playsinline='true' loop={backgroundVideo.loop} poster={backgroundVideo.poster?.source}>
                            <source src={backgroundVideo?.files?.items[0].source} type='video/mp4' />
                        </video>
                    </div>}

                {layout === 'text' && <div className={styles.boxOneCopy} style={{
        justifyContent: justifyContent
      }}>
                        {copyImage && copyImageLocation === 'top' && <div className={styles.copyImage}>
                                <Image src={copyImage?.url ?? ''} width={copyImage?.width} height={copyImage?.height} alt={copyImage?.alt ?? ''} />
                            </div>}

                        {isMarketplace && <div className={styles.marketplaceSpacer} />}

                        <div className={`${styles.heading} ${headingColor === 'gradient' ? styles.headinggradient : ''}`} style={getHeadingStyle()}>
                            <Markdown tag='h3' typeStyle={{
            _: 'heading-lg',
            md: 'heading-md'
          }} markdown={renderTemplate(headingMd, personalizations)} color={headingColor} />
                        </div>

                        <div className={styles.subtitle}>
                            <Markdown tag='p' typeStyle={{
            _: 'body-lg',
            md: 'body-md'
          }} markdown={renderTemplate(subtitleMd, personalizations)} />
                        </div>

                        {copyImage && copyImageLocation === 'bottom' && <div style={{
          marginBottom: '24px'
        }}>
                                <Image src={copyImage?.url ?? ''} width={copyImage?.width} height={copyImage?.height} alt={copyImage?.alt ?? ''} />
                            </div>}

                        <div className={styles.buttons}>
                            {!!button && <ActionButton preset={button?.preset} {...button} />}
                        </div>
                    </div>}

                {layout === 'image' && image && <div className={styles.media}>
                        <div className={styles.imageContainer} style={{
          alignItems: alignImage
        }}>
                            <div className={styles.boxOneImage}>
                                {image && <Image className={styles.image} src={image?.url ?? ''} width={image?.width} height={image?.height} alt={image?.alt ?? ''} />}
                            </div>
                        </div>
                    </div>}
            </div>
        </div>;
};
export default BentoSquare;
type Props = Omit<BentoPieceSquareFragment, 'sys' | 'type'> & {
  index?: number;
  layout?: string | undefined;
};